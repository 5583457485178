<template>
    <div>
        <ShareDrawer />
        <ChatCreate />
    </div>
</template>

<script>
import chat from "./store/index"
import share from './store/share'
import ShareDrawer from './components/ShareDrawer'
import ChatCreate from './components/ChatCreate'
import eventBus from './utils/ChatEventBus'
import soketsMessages from './soketsMessages'
export default {
    name: "ChatInit",
    components: {
        ShareDrawer,
        ChatCreate,
    },
    mixins: [soketsMessages],
    created() {
        if(!this.$store.hasModule('chat'))
            this.$store.registerModule("chat", chat)

        if(!this.$store.hasModule('share'))
            this.$store.registerModule("share", share)
    },
    methods: {
        openNoty(data, key){
            if(this.isMobile) {
                if(this.$route.name === 'chat-body' && this.$route.params.id === data.chat_uid)
                    return null
                else {
                    this.$router.push({
                        name: 'chat-body',
                        params: {
                            id: data.chat_uid
                        }
                    })
                }
                
            } else {
                if(this.$route.name !=='chat') { 
                    this.$router.push({name: 'chat', query: {chat_id: data.chat_uid}})
                } else {
                    this.$store.commit('chat/SET_ACTIVE_CHAT_FROM_UID', data.chat_uid)  
                    this.$router.replace({name: 'chat', query: {}})
                    this.$router.push({name: 'chat', query: {chat_id: data.chat_uid}})
                }
            }
            
            eventBus.$emit('arreaScrollDown')
            this.$notification.close(key)
        },
        async getMessageCount() {
            try {
                await this.$store.dispatch('chat/getMessageCount')   
            } catch(e) {
                console.log(e)
            }
        }
    },
    mounted() {
        this.getMessageCount()
        eventBus.$on('CHAT_SHOW_NEW_MESSAGE', data => {
            if(this.isMobile) {
                this.$notification.destroy()
            }
            const key = data.message_uid

            let messageImages = [],
                messageFiles = []
            if(data.attachments?.length) {
                messageImages = data.attachments.filter(f => f.is_image)
                messageFiles = data.attachments.filter(f => f.obj_type === 'file' && !f.is_image)
            }

            const title = data.is_public ? data.chat_name : data.message_author?.full_name || 'Уведомление'
            this.$notification.open({
                message: (h) => {
                    return h('div', { class: 'notify_head' }, [h('div', { class: 'notify_title' }, title), h('div', { class: 'notify_date' }, `${this.$moment(data.created).format('HH:mm')}`)])
                },
                description: (h) => {
                    return h('div', 
                        { class: 'notify_message'}, 
                        [
                            data.message_reply && h('div', { class: 'notify_reply' }, `Ответ на сообщение: ${data.message_reply.text}`), 
                            h('div', data.text),
                            messageImages.length ?
                                h('div', { class: `notify_gallery ${messageImages.length > 1 && 'mult_gal'} ${data.text && 'ty'}` }, messageImages.map((image) => {
                                    return h('div', { class: `notify_image_wrap ${messageImages.length > 1 ? 'mult' : 'one'} ${data.text && 'te'}` }, [h('img', { attrs: { src: image.path } })])
                                })) : h('div', ''),
                            messageFiles.length ?
                                h('div', { class: `notify_files ${data.text && 'ty'} ${messageImages.length && 'image_gal'}` }, messageFiles.map((file) => {
                                    return h('div', { class: 'notify_file_wrap'}, [h('i', { class: 'fi fi-rr-file' }), h('span', file.name)])
                                })) : h('div', '')
                        ]
                    )
                },
                duration: 5,
                top: this.isMobile ? '50px' : '60px',
                key,
                closeIcon: (h) => {
                    if(this.isMobile) {
                        return h('div', { class: 'notify_close' }, 'Закрыть')
                    } else
                        return h('i', { class: 'fi fi-rr-cross' })
                },
                icon: (h) => {
                    return h('a-avatar', {
                        attrs: {
                            flaticon: true,
                            src: data.message_author?.avatar?.path ? data.message_author.avatar.path : null,
                            icon: 'fi-rr-comment-dots'
                        }
                    })
                }, 
                class: 'cursor-pointer notify_popover notify_message',
                onClick: ()=> this.openNoty(data, key),
                onClose: this.$notification.close(key)
            })
        })
    },
    beforeDestroy() {
        eventBus.$off('CHAT_SHOW_NEW_MESSAGE')
    }
}
</script>
<style scoped>
.noty_message{
    width: 300px;
}
</style>